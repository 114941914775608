import l, { useRef as i, useEffect as a } from "react";
function d(t) {
  if (document.querySelector(`script[src="${t}"]`))
    return;
  const e = document.createElement("script");
  e.src = t, e.async = !0, e.type = "module", document.head.appendChild(e);
}
const u = [
  "loaded",
  "comment:published",
  "comment:edited",
  "comment:deleted",
  "comment:voted",
  "comment:flagged",
  "reaction",
  "rating",
  "auth:login:clicked"
];
function f(t, e, r) {
  d("https://talk.hyvor.com/embed/embed.js"), customElements.whenDefined("hyvor-talk-comments").then(() => {
    const c = document.createElement("hyvor-talk-comments");
    for (const [n, o] of Object.entries(t))
      o !== void 0 && (n === "settings" || n === "translations" ? c[n] = o : c.setAttribute(n, o));
    u.forEach((n) => {
      c.addEventListener(n, (o) => {
        r(n, o.detail);
      });
    }), e.innerHTML = "", e.appendChild(c);
  });
}
function h(t = {}, e = null) {
  customElements.whenDefined("hyvor-talk-comment-count").then(() => {
    window.hyvorTalkCommentCounts.load(t, e);
  });
}
function p(t) {
  d("https://talk.hyvor.com/embed/comment-counts.js"), t.loading !== "manual" && h();
}
function y(t) {
  const e = i(null), { on: r, ...c } = t;
  return a(() => {
    f(
      c,
      e.current,
      (n, o) => {
        var s;
        const m = (s = t.on) == null ? void 0 : s[n];
        m && typeof m == "function" && m(o);
      }
    );
  }, []), /* @__PURE__ */ l.createElement("div", { ref: e });
}
function k(t) {
  return a(() => p(t), []), /* @__PURE__ */ l.createElement("hyvor-talk-comment-count", { ...t });
}
export {
  k as CommentCount,
  y as Comments,
  h as loadCommentCounts
};
